var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "page-wrap" }, [
    _c(
      "section",
      { staticClass: "header" },
      [
        _c("form-filter", {
          ref: "refFormFilter",
          attrs: { pageParams: _vm.pageParams },
        }),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "content-wrap" },
      [
        _c("table-list", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingData,
              expression: "loadingData",
            },
          ],
          attrs: { tableData: _vm.tableData },
        }),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "pagination" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            "current-page": _vm.pageParams.page,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.pageParams.pageSize,
            total: _vm.total,
            layout: "total, sizes, prev, pager, next, jumper",
            background: "",
          },
          on: {
            "size-change": _vm.onHandleSizeChange,
            "current-change": _vm.onHandleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }